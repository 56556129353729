import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import { globalStyles } from '../components/layout.styles'
import ScrollAnim from 'rc-scroll-anim'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import OlimpiadaPoketCoronaVirus from '../components/OlimpiadaPoketCoronaVirus'
import Footer from '../components/Footer'
import Calendar from '../images/calendar.svg'
import ArrowDown from '../images/arrowDown.png'
import Selo from '../images/selo.png'

import {
  header,
  bg1,
  btn,
  title,
  arrow
} from '../components/styles/InternalPages.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Olimpíada Pocket Coronavírus" />
    <NavBar location={props.location} />
    <div css={header}>
      <div css={bg1} style={{ marginTop: 84 }}>
        <div style={{ paddingTop: 40, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection:'column' }}>
          <img src={Selo} alt='Selo' style={{ marginBottom: 32 }} />
          <div css={title}>Coronavírus</div>
          <h2><img src={Calendar} alt='calendar' /> 12 a 16 de Março</h2>
          <h1>APURAR, CHECAR,<br /> APRENDER E INFORMAR.</h1>
          <a href='https://fractalid.fractaltecnologia.com.br/?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn}>ACESSE A PROVA</a>
          <ScrollAnim.Link
            to='aplicacao'
            offsetTop='120'
          >
            <div css={arrow} style={{ marginTop: 190 }}>
              Saiba mais<br />
              <img src={ArrowDown} alt='/' />
            </div>
          </ScrollAnim.Link>
        </div>
      </div>
      <div id='aplicacao' />
      <OlimpiadaPoketCoronaVirus />
    </div>
    <Footer />
  </Fragment>
)

export default StudentsPage
